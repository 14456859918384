<template>
  <v-menu v-model="userMenu" v-if="profile">
    <template v-slot:activator="{ on }">
      <div v-on="on">
        <v-chip v-if="$vuetify.breakpoint.smAndUp" pill class="mx-3">
          <v-avatar dark color="primary white--text" left>
            {{ profile.initials }}
          </v-avatar>
          {{ profile.name || profile.email }}
          <v-icon right> mdi-menu-down </v-icon>
        </v-chip>
        <v-avatar
          v-if="$vuetify.breakpoint.xsOnly"
          dark
          size="32"
          color="primary white--text"
        >
          {{ profile.initials }}
        </v-avatar>
      </div>
    </template>
    <v-card>
      <v-list dark>
        <v-list-item two-line>
          <v-list-item-avatar color="grey">
            {{ profile.initials }}
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ profile.name || profile.email }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list dense light>
        <v-divider />
        <v-list-item @click="signOut">
          <v-list-item-action>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-action>
          <v-list-item-subtitle>
            {{ $t('auth.logout') }}
          </v-list-item-subtitle>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'ProfileAppBar',
  components: {},
  data() {
    return {
      userMenu: false,
    }
  },
  computed: {
    ...mapState({
      profile: (state) => state.auth.user,
    }),
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
    }),
  },
  methods: {
    async signOut() {
      await this.$store.dispatch('auth/logout')
      this.$router.push('/login')
    },
  },
}
</script>

<i18n>
{
    "en": {
        "logout": "Logout"
    },
    "tr": {
        "logout": "Çıkış Yap"
    }
}
</i18n>
