<template>
  <v-app>
    <v-app-bar app>
      <v-toolbar-title>Zenger Clicker v2</v-toolbar-title>
      <v-spacer />
      <ProfileAppBar />
    </v-app-bar>
    <v-main>
        <router-view />
    </v-main>
  </v-app>
</template>

<script>
import ProfileAppBar from '../components/ProfileAppBar'

export default {
  name: 'DefaultLayout',
  components: {
    ProfileAppBar
  },
  data () {
    return {
      drawer: false,
      menu: [
      ]
    }
  }
}
</script>

<style>
</style>
